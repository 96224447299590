import axios from 'axios';
import { cookieCleanup } from '../utils/commonUtil';

const apiURL: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

export const authApi = {
  googleLogin: async (credential: string) => {
    try {
      const response = await axios.post(`${apiURL}/auth/google`, { credential });
      return response.data;
    } catch (error) {
      console.error('Error during Google login:', error);
      throw error;
    }
  },

  logout: async () => {
    try {
      await api.post('/logout', {},{});
      cookieCleanup();
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  },

  validateSession: async (userEmail: string) => {
    const response = await api.post('/validate_session', {},{});
    return response.data;
  }
};