import React, { createContext, useState, ReactNode, useCallback, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { researchApi } from '../apis/research'; 
import { createNewSession, getUserDetails } from '../utils/commonUtil';
import { useAuth } from '../contexts/AuthContext';
import {SessionInfo} from '../utils/types';
import { v4 as uuidv4 } from 'uuid';
import { CheckBoxMap, FilteredSource, HighlightMap, IHash } from '../components/research/SourceComponent';

// Create an axios instance with a base URL
const apiURL: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000'; // Default to localhost if not set
// const apiKey: string = process.env.REACT_APP_API_KEY || "";

// Create an axios instance with a base URL
const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

const headers = {
    'Content-Type': 'application/json',
    // 'X-API-Key': apiKey,
};


interface SourceComponentDataProps {
  filteredSources : FilteredSource[];
  checkBoxMap : CheckBoxMap;
  highlightMap : HighlightMap;
  sourceIdToResultId : CheckBoxMap;
  highlightState: { highlightCheckboxId: number , highlightResultId: number, highlightTabId: number};
}

interface Source {
  result_id: number;
  title: string;
  url: string;
  url_with_highlight: string,
  source_domain: string;
  snippet: string;
  source_type: string;
  source_date: string;
}

interface Bookmark {
  bookmark_id : number,
  answer_id: number
}

export interface SuggestedQuestion {
  suggested_question_id: number;
  question_text: string;
}

interface SelectedSource {
  result_id: number;
  title: string;
  url: string;
  url_with_highlight: string,
  source_domain: string;
  snippet: string;
  source_type: string;
  source_date: string;
}

interface ChatDataContextProps {
  query: string;
  handleQuery: (query: string) => Promise<void>;
  answer: string;
  sources: { [key: number]: Source[] };
  suggestedQuestions: SuggestedQuestion[];
  lastRenderedQueryId: number | null;
  answerReady: boolean;
  setAnswerReady: (answerReady: boolean) => void;
  fetchingInProgress: boolean;
  setLastRenderedQueryId: (lastRenderedQueryId: number | null) => void;
  chatHistory: ChatHistoryEntry[];
  handleOldSession: (sessionId: string, answerId: number | null) => Promise<boolean>;
  highlightedSource: HighlightedSource | null;
  setHighlightedSource: (highlightedSource: HighlightedSource | null) => void;
  currentQuery: string;
  setCurrentQuery: (query: string) => void;
  sessionId: string | null;
  setSessionId: (sessionId: string) => void;
  resetSessionDataForNewSession: (sessionId: string) => void;
  queryId: number | null;
  selectedBookmarks: [];
  updateBookmarks: () => void;
  highlightedAnswerId: number | null;
  setHighlightedAnswerId: ( highlightedAnswerId: number | null ) => void;
  chatProgressComponent: ChatProgressComponent;
  isMobile: boolean;
  handleSummarizeSelected: (selectedSources: SelectedSource[]) => Promise<void>;
  isSummarizing: boolean;
  refreshUser: () => void;
  sourceQueryId: number | null;
  setSourceQueryId: (sourceQueryId: number | null) => void;
  switchSources: (sourceId: number, queryId: number, answerId: number ) => Promise<void>;
  sourceComponentData: SourceComponentDataProps;
  resetSourceComponentHighlightState: () => void;
}

interface ChatHistoryEntry {
  query: string;
  answer: string;
  queryId: number;
  answerId: number;
  userFeedback: number | null;
}

interface HighlightedSource {
  sourceIndex: number;
  queryId: number;
  answerId: number;
}

interface ChatProgressComponent { 
  stage: QueryStatus;
  subQueryList: [];
  searchLinks : [];
}

export enum QueryStatus {
  SEARCH_PROGRESS = "search_progress",
  PROCESSED_QUERY = "processed_query",
  SEARCH_RESULTS = "search_results",
  IK_SEARCH_RESULTS = "ik_search_results",
  WEB_DOCUMENTS_LOADED = "web_documents_loaded",
  FILTERED_WEB_DOCUMENTS = "filtered_web_documents",
  FINAL_RERANKED_RESULTS = "final_reranked_results",
  FINAL_RESULTS = "final_results"
}


export const ChatDataContext = createContext<ChatDataContextProps | undefined>(undefined);

export const ChatDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [query, setQuery] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [sources, setSources] = useState<{[key: number]: Source[]}>({});
  const [suggestedQuestions, setSuggestedQuestions] = useState<SuggestedQuestion[]>([]);
  const [lastRenderedQueryId, setLastRenderedQueryId] = useState<number | null>(null);
  const [answerReady, setAnswerReady] = useState<boolean>(false);
  const [fetchingInProgress, setFetchingInProgress] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<ChatHistoryEntry[]>([]);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [highlightedSource, setHighlightedSource] = useState<HighlightedSource | null>(null);
  const [highlightedAnswerId, setHighlightedAnswerId] = useState<number | null >(null);
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const [queryId, setQueryId] = useState<number | null>(null);
  const [answerId, setAnswerId] = useState<number | null>(null);
  const [selectedBookmarks, setSelectedBookmarks] = useState<[]>([]);
  const [chatProgressComponent, setChatProgressComponent] = useState<ChatProgressComponent>({
    stage: QueryStatus.SEARCH_PROGRESS,
    subQueryList: [],
    searchLinks: []
  })
  const [isMobile, setIsMobile] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState<boolean>(false);
  const [sourceQueryId, setSourceQueryId] = useState<number |  null>(null);
  const [sourceComponentData, setSourceComponentData] = useState<SourceComponentDataProps>({} as SourceComponentDataProps);

  useEffect(() => {
    // Check if the device is mobile
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };
  
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  },[]);


  const { user, setUser } = useAuth();

  const updateBookmarks = useCallback(() => {
    async function updateBookmark() {
      try {
        if(user){
          const allUserBookmarks = await researchApi.getBookmarks(user.user_id);
          setSelectedBookmarks(allUserBookmarks.map((bookmark : Bookmark) => ({ answerId : bookmark.answer_id, bookmarkId : bookmark.bookmark_id})));
        }
      }catch (error) {
        console.error('Error processing query:', error);
      }
    }
    updateBookmark();
  },[user]);

  const refreshUser = useCallback(() => {
    async function refreshUserDetails(email: string) {
      const response = await getUserDetails(email);
      localStorage.setItem('user', JSON.stringify(response));
      setUser(response);
    }
    if(user?.user_email) {
      refreshUserDetails(user.user_email);
    }
  }, [setUser, user?.user_email]);

  const generateSourceComponentData = useCallback((queryId: number, sources: {[key: number]: Source[]}, highlightSourceIndex: number = 0, hasSourceChanged: boolean = true) => {
    const tempSourceComponentData: SourceComponentDataProps = {} as SourceComponentDataProps;
    
    const tempSourceIdToResultId: CheckBoxMap = {};
    const tempFilteredSources: FilteredSource[] = [];
    const tempCheckBoxMap: CheckBoxMap = {};
    const tempHighlightMap : HighlightMap = {};
    const tempSourceUrl: IHash = {};

    let id = 0;
    let checkbox_group_id = 0;
    let tab_id = 0;

    if(queryId) {
      const currentSources = sources[queryId];
      for (const source of currentSources) {
        id++;
        const newSource = { ...source, id: id, more_from_source: []};
        tempSourceIdToResultId[id] = source.result_id; // Set the mapping
        if(source.url in tempSourceUrl){
          tempFilteredSources[tempSourceUrl[source.url]].more_from_source.push(newSource);
          tempCheckBoxMap[source.result_id] = tempFilteredSources[tempSourceUrl[source.url]].result_id 
          tab_id = tempFilteredSources[tempSourceUrl[source.url]].more_from_source.length + 1;
        } else{
          tempFilteredSources.push(newSource);
          tempSourceUrl[source.url] = tempFilteredSources.length - 1 ;
          tempCheckBoxMap[source.result_id] = source.result_id;
          tab_id = 1;
        }
        checkbox_group_id = tempSourceUrl[source.url] + 1;
        tempHighlightMap[id] = [checkbox_group_id, tab_id];
      }
      

      tempSourceComponentData["checkBoxMap"] = tempCheckBoxMap;
      tempSourceComponentData["filteredSources"] = tempFilteredSources;
      if(highlightSourceIndex){
        const highlightResultId = tempCheckBoxMap[tempSourceIdToResultId[highlightSourceIndex]]
        const [highlightCheckboxId, highlightTabId] = tempHighlightMap[highlightSourceIndex]
        tempSourceComponentData["highlightState"] = {highlightCheckboxId, highlightResultId, highlightTabId};
      }
      if(hasSourceChanged){
        setSourceComponentData(tempSourceComponentData);
        setSourceQueryId(queryId);
      } else if(highlightSourceIndex){
        setSourceComponentData(prev => ({
          ...prev,
          highlightState : tempSourceComponentData["highlightState"]
        }))
      }
      return true;
    }
  }, []);

  const resetSourceComponentHighlightState = useCallback(() => {
    setSourceComponentData(prev => ({ ...prev, highlightState : {highlightCheckboxId : 0, highlightResultId : 0, highlightTabId: 0}}));
  },[])

  const resetSessionDataForNewSession = (newSessionId : string) => {
    setQuery('');
    setAnswer('');
    setSources({});
    setSourceComponentData({} as SourceComponentDataProps);
    setSourceQueryId(null);
    setSuggestedQuestions([]);
    setAnswerReady(false);
    setCurrentQuery('');
    setChatHistory([]);
    setSessionId(newSessionId);
    setSelectedBookmarks([]);
    setLastRenderedQueryId(null);
    setFetchingInProgress(false);
  };

  const processBuffer = useCallback((buffer: string) : string => {
    const parts = buffer.split("\n");
    let validJson = ""; 

    for (let part of parts) {
      part = part.trim();
      if (part) {
        try {
          const currentValue = JSON.parse(part);
          if(currentValue.type === QueryStatus.PROCESSED_QUERY && currentValue.data) {
            if (currentValue.data.sub_queries && Array.isArray(currentValue.data.sub_queries)) {
              const subQueryList = currentValue.data.sub_queries.map((subQuery: any) => (subQuery.query));
              setChatProgressComponent(prev => ({...prev, "stage" : QueryStatus.PROCESSED_QUERY , subQueryList}));
          }
          } else if(currentValue.type === QueryStatus.SEARCH_RESULTS) {
            if (currentValue.data.search_links && Array.isArray(currentValue.data.search_links)) {
              const searchLinks = currentValue.data.search_links;
              setChatProgressComponent(prev => ({...prev, "stage" : QueryStatus.SEARCH_RESULTS, searchLinks}));
            }
          } else if (currentValue.type === QueryStatus.FINAL_RERANKED_RESULTS) {
            setChatProgressComponent(prev => ({...prev, "stage" : QueryStatus.FINAL_RERANKED_RESULTS}));
          } else if (currentValue.type === QueryStatus.FINAL_RESULTS) {
            setChatProgressComponent(prev => ({...prev, "stage" : QueryStatus.FINAL_RESULTS}));
            return currentValue.data;
          }
        } catch (error) {
          // If parsing fails, keep the part in validJson for the next iteration
          validJson += part;
        }
      }
    }

    // Update the buffer to only keep the valid JSON parts
    return validJson;
  },[]);

  const handleQuery = useCallback(async (newQuery: string) => {
    setQuery(newQuery);
    setCurrentQuery('');
    setAnswerReady(false);
    setFetchingInProgress(true);
    setSources({});
    setSourceComponentData({} as SourceComponentDataProps);
    setSourceQueryId(null);
    setSuggestedQuestions([]);
    setChatProgressComponent({"stage" : QueryStatus.SEARCH_PROGRESS , subQueryList : [] , searchLinks : []})
    // setSources({});
    setAnswer('');
    // setSearchResultParsedState({} as SearchResultParsedStateMap);

    let queryId : number;
    try {

      // Step 1: Create a new session (if needed)
      let currentSessionId = await createNewSession();
      setSessionId(currentSessionId.session_id);

      // Step 2: Create a new query
      console.log('Creating new query');
      const queryResponse = await api.post('/create_query', {
        session_id: currentSessionId.session_id,
        query_text: newQuery
      }, {
        headers: headers
      });

      queryId = queryResponse.data.query_id;
      setLastRenderedQueryId(queryId);
      var final_answer = '';
      let userFeedback: number | null = null;

      setChatHistory(prevHistory => [...prevHistory, { query: newQuery, answer: final_answer, queryId: queryId, answerId: -1, userFeedback: userFeedback }]);
      setQueryId(queryId);
      setLastRenderedQueryId(-1);

      const searchResultsResponse = await fetch(`${api.defaults.baseURL}/get_search_results`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          query_id: queryId,
          session_id: currentSessionId.session_id,
        }),
        credentials: "include"
      });

      if (!searchResultsResponse.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const  searchResultsReader= searchResultsResponse.body.getReader();
      const searchResultsDecoder = new TextDecoder();
      var searchResults : Source[] = [];
      let invalidJson = ""; //Use a smaller buffer to accumulate invalid Json chunks
      while (true) {
        const { value, done: doneReading } = await searchResultsReader.read();
        const chunk = searchResultsDecoder.decode(value);
        if (doneReading) {
          if (chunk) {
            invalidJson = processBuffer(invalidJson + chunk);
          }
          const finalSearchResults= JSON.parse(invalidJson) as Source[];
          if(Array.isArray(finalSearchResults)){
            searchResults.push(...finalSearchResults);
            setSources( prev => {
              const tempSourceList = {...prev , [queryId] : searchResults }
              generateSourceComponentData(queryId, tempSourceList);
              return tempSourceList;
            });
          }
          setFetchingInProgress(false);
          break;
        } else {
          // console.log(chunk);
          invalidJson = processBuffer(invalidJson + chunk); 
        }
      }

      // Step 4: Get summarized answer
      const response = await fetch(`${api.defaults.baseURL}/get_summarized_answer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          session_id: currentSessionId.session_id,
          query_id: queryId,
          result_ids: searchResults.map((result: Source) => result.result_id)
        }),
        credentials: "include"
      });

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      
      while (true) {
        const { value, done: doneReading } = await reader.read();
        const chunk = decoder.decode(value);
        if (doneReading) {
          setAnswerReady(true);
          setLastRenderedQueryId(null);
          break;
        } else {
          final_answer += chunk;
          setAnswer(prev => prev + chunk);
        }
      }

      const suggestedQuestionsResponse = await api.post('/get_suggested_questions', {
        session_id: currentSessionId.session_id,
        query_id: queryId,
      }, {
        headers: headers
      }); 
      const suggestedQuestions = suggestedQuestionsResponse.data;
      setSuggestedQuestions(suggestedQuestions);

      // need this to set answer_id etc for future operations
      const sessionDetailsResponse = await researchApi.getSessionDetails(String(currentSessionId.session_id));
      const lastAnswerId = sessionDetailsResponse.answers[sessionDetailsResponse.answers.length - 1].answer_id;
      for (const feedback of sessionDetailsResponse.user_feedback) {
        if (feedback.answer_id === lastAnswerId) {
          userFeedback = feedback.feedback_value;
          break;
        }
      }

      setAnswerId(lastAnswerId);
      setLastRenderedQueryId(queryId);
      setChatHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1] = {
          ...updatedHistory[updatedHistory.length - 1],
          answer: final_answer,
          answerId: lastAnswerId,
          userFeedback: userFeedback
        };
        return updatedHistory;
      });
      updateBookmarks();

    } catch (error) {
      console.error('Error processing query:', error);
      setSources( prev => ( {...prev, queryId : [] }));
      setSuggestedQuestions([]);
      setAnswerReady(false);
      // setChatHistory([]);
      setSelectedBookmarks([]);
      if (error instanceof AxiosError && error.status === 402) {
        console.error('Payment Required: Please check your payment status.');
        setFetchingInProgress(false);
        setLastRenderedQueryId(null);
        setQuery('');
        throw new Error('INSUFFICIENT_TOKENS');
      }
      setAnswer('An error occurred while processing your query. Please try again.');
    } finally {
      refreshUser();
    }
  }, [refreshUser, updateBookmarks, processBuffer, generateSourceComponentData]);

  const handleOldSession = useCallback(async (sessionId: string, answerId: number | null) => {

    setChatHistory([]);
    setSuggestedQuestions([]);
    setSources({});
    setSourceComponentData({} as SourceComponentDataProps);
    setQueryId(null);
    setSourceQueryId(null);
    setAnswerId(null);
    setAnswerReady(false);
    setSelectedBookmarks([]);
    updateBookmarks();

    try {
      // Step 1: Get session details
      const sessionDetailsResponse = await researchApi.getSessionDetails(sessionId);
      const sessionInfo:SessionInfo = {
        session_id : sessionDetailsResponse.session_id , 
        user_id: Number(user?.user_id), 
        created_at: sessionDetailsResponse.created_at,
        name: sessionDetailsResponse.name
      }
      localStorage.setItem('session', JSON.stringify(sessionInfo));
      setSessionId(sessionId);

      if (sessionDetailsResponse.queries.length === 0) {
        return true;
      }

      const lastAnswerId = sessionDetailsResponse.answers[sessionDetailsResponse.answers.length - 1].answer_id;
      const firstAnswerId = sessionDetailsResponse.answers[0].answer_id;
      setAnswerId(lastAnswerId);

      const queries = sessionDetailsResponse.queries;
      const answers = sessionDetailsResponse.answers;
      const userFeedback = sessionDetailsResponse.user_feedback;
      var sourceQueryId = null;

      let formattedChatHistory = [];
      for (let index = 0; index < queries.length; index++) {
        const query = queries[index];
        const answer = answers.find((a: any) => a.query_id === query.query_id) || { answer_text: '', answer_id: null };
        const user_feedback = userFeedback.find((a: any) => a.answer_id === answer.answer_id) || { feedback_value: null };

        if(answer.answer_id === firstAnswerId){
          sourceQueryId = answer.query_id;
        }

        formattedChatHistory.push({
          query: query.query_text,
          queryId: query.query_id,  
          answer: answer.answer_text || '',
          answerId: answer.answer_id || null,
          userFeedback: user_feedback.feedback_value || null,
        });
      }
      setChatHistory(formattedChatHistory);

      // suggested questions available from the same resp as above sessionDetailsResponse 
      setSuggestedQuestions(sessionDetailsResponse.suggested_questions)

      let lastQueryId = sessionDetailsResponse.queries[sessionDetailsResponse.queries.length - 1].query_id;
      let firstQueryId = sessionDetailsResponse.queries[0].query_id;
      setQueryId(lastQueryId);
      
      // Step 3: Get search results
      const searchResultsResponse = await researchApi.getSessionSearchResultsFromDB(sessionId);
      const tempSourceList: { [key: number]: Source[] } = {};
      for(let searchResult of searchResultsResponse){
        const queryId = searchResult.query_id;
  
        // If this query_id doesn't exist in the object yet, initialize it with an empty array
        if (!tempSourceList[queryId]) {
          tempSourceList[queryId] = [];
        }
    
        // Add the current searchResult to the array for this query_id
        tempSourceList[queryId].push(searchResult as Source);
      }

      

      setSources(tempSourceList);
      generateSourceComponentData(sourceQueryId? sourceQueryId: lastQueryId, tempSourceList, 0, true);
      
      updateBookmarks();     
      return true;

    } catch (error) {
      console.error('Error handling old session:', error);
      setChatHistory([]);
      setSuggestedQuestions([]);
      setSources({});
      setSourceComponentData({} as SourceComponentDataProps);
      setQueryId(null);
      setSourceQueryId(null);
      setAnswerId(null);
      setAnswerReady(false);
      setSelectedBookmarks([]);
      return false;
    } 
  }, [updateBookmarks, user?.user_id, generateSourceComponentData]);


  const handleSummarizeSelected = useCallback(async (selectedSources: SelectedSource[]) => {
    
    setFetchingInProgress(true);
    setLastRenderedQueryId(queryId);
    setCurrentQuery('');
    setIsSummarizing(true);
    setSuggestedQuestions([]);
    // setSources(selectedSources)
    setQueryId(null);
    setSourceComponentData({} as SourceComponentDataProps);
    setSourceQueryId(null);
    setAnswer('');
    setAnswerId(null);
    setAnswerReady(false);
    setSelectedBookmarks([]);
    updateBookmarks();
    setChatProgressComponent({ stage: QueryStatus.SEARCH_PROGRESS, subQueryList: [], searchLinks: [] });

    try {
      const currentSessionId = JSON.parse(localStorage.getItem('session') || '{}').session_id;

      // Validate result_ids before sending to the backend
      const validResultIds = selectedSources.map(source => source.result_id).filter(id => id && typeof id === 'number');

      if (validResultIds.length === 0) {
        throw new Error('No valid source IDs found');
      }

      const qresponse = await fetch(`${api.defaults.baseURL}/get_query`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query_id: sourceQueryId }),
        credentials: "include"
      });
    
      if (!qresponse.ok) {
        throw new Error(`HTTP error! status: ${qresponse.status}`);
      }

      const queryData = await qresponse.json();
      console.log("queryData: ", queryData)
      const originalQueryText = queryData.query_text;

      // Get the count of resummarizations for this original query
      /* const sessionDetailsResponse1 = await researchApi.getSessionDetails(String(currentSessionId));
      const resummarizationCount = sessionDetailsResponse1.queries.filter(
        (q: any) => q.query_text === originalQueryText
      ).length; */
      const resummarizationCount = chatHistory.filter(row => row.query === originalQueryText).length;

      // Update the query text with the new resummarization index
      const queryText = `${originalQueryText} [Resummarized ${resummarizationCount}]`;

      setQuery(queryText);

      // Get summarized answer
      const response = await fetch(`${api.defaults.baseURL}/get_summarized_answer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          session_id: currentSessionId,
          query_id: sourceQueryId, // Use the selected source queryId
          result_ids: validResultIds,
          is_regeneration: true,
          original_query_id: sourceQueryId // Use the selected source queryId as the original_query_id
        }),
        credentials: "include"
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API error: ${errorData.detail || response.statusText}`);
      }

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let final_answer = '';
      
      while (true) {
        const { value, done: doneReading } = await reader.read();
        const chunk = decoder.decode(value);
        if (doneReading) {
          setAnswerReady(true);
          break;
        } else {
          final_answer += chunk;
          setAnswer(prev => prev + chunk);
        }
      }

      // Get suggested questions
      const suggestedQuestionsResponse = await api.post('/get_suggested_questions', {
        session_id: currentSessionId,
        query_id: sourceQueryId,
      }, {
        headers: headers
      }); 
      const suggestedQuestions = suggestedQuestionsResponse.data;
      setSuggestedQuestions(suggestedQuestions);

      // Update answer_id
      const sessionDetailsResponse = await researchApi.getSessionDetails(String(currentSessionId));
      const lastQueryId = sessionDetailsResponse.queries[sessionDetailsResponse.queries.length - 1].query_id;
      const lastAnswer = sessionDetailsResponse.answers.find((answer: any) => answer.query_id === lastQueryId);
      const lastAnswerId = lastAnswer ? lastAnswer.answer_id : null;
      const lastUserFeedback = sessionDetailsResponse.user_feedback.find((feedback: any) => feedback.answer_id === lastAnswerId);
      const lastUserFeedbackValue = lastUserFeedback ? lastUserFeedback.feedback_value : null;

      const searchResultsResponse = await researchApi.getSearchResultsFromDB(lastQueryId, sessionId as string);
      const searchResults = JSON.parse(JSON.parse(searchResultsResponse)["data"]) as Source[];
      if(Array.isArray(searchResults)){
        setSources( prev => {
          const tempSourceList = { ...prev, [lastQueryId] : searchResults};
          generateSourceComponentData(lastQueryId, tempSourceList);
          return tempSourceList;
        });
        setFetchingInProgress(false);
      }
      
      setQueryId(lastQueryId);
      setAnswerId(lastAnswerId);
      setChatHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1] = {
          ...updatedHistory[updatedHistory.length - 1],
          answer: final_answer,
          answerId: lastAnswerId,
          userFeedback: lastUserFeedbackValue
        };
        return updatedHistory;
      });
    
      updateBookmarks();
    } catch (error) {
      console.error('Error processing summarization:', error);
      setAnswer('An error occurred while summarizing the selected sources. Please try again.');
      setSuggestedQuestions([]);
    } finally {
      setLastRenderedQueryId(null);
      setFetchingInProgress(false);
      setIsSummarizing(false);  // Set this back to false when done
    }
  }, [queryId, updateBookmarks, sourceQueryId, chatHistory, sessionId, generateSourceComponentData]);


  const switchSources = useCallback(async (citationNumberId: number, queryId: number, answerId: number) => {
    const hasSourceChanged = queryId !== sourceQueryId;
    if(hasSourceChanged) {
      if(!sources[queryId]){
        setFetchingInProgress(true);
        const searchResultsResponse = await researchApi.getSearchResultsFromDB(queryId, sessionId as string);
        const searchResults = JSON.parse(JSON.parse(searchResultsResponse)["data"]) as Source[];
        if(Array.isArray(searchResults)){
          setSources( prev => {
            const tempSourceList = { ...prev, [queryId] : searchResults};
            generateSourceComponentData(queryId, tempSourceList, citationNumberId);
            return tempSourceList;
          });
        }
        setFetchingInProgress(false);
      }else {
        generateSourceComponentData(queryId, sources, citationNumberId);
      }
    }else if(citationNumberId !== 0) {
      generateSourceComponentData(queryId, sources, citationNumberId, hasSourceChanged);
    }
  },[sourceQueryId, sources, sessionId, generateSourceComponentData]);


  return (
    <ChatDataContext.Provider value={{
      query,
      handleQuery,
      answer,
      sources,
      suggestedQuestions,
      lastRenderedQueryId,
      answerReady,
      setAnswerReady,
      fetchingInProgress,
      setLastRenderedQueryId,
      chatHistory,
      handleOldSession,
      highlightedSource,
      setHighlightedSource,
      currentQuery,
      setCurrentQuery,
      sessionId,
      setSessionId,
      resetSessionDataForNewSession,
      queryId,
      selectedBookmarks,
      updateBookmarks,
      highlightedAnswerId,
      setHighlightedAnswerId,
      chatProgressComponent,
      isMobile,
      handleSummarizeSelected,
      isSummarizing,
      refreshUser,
      sourceQueryId,
      setSourceQueryId,
      switchSources,
      sourceComponentData,
      resetSourceComponentHighlightState
    }}>
      {children}
    </ChatDataContext.Provider>
  );
};

export default ChatDataProvider;
