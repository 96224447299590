import axios from 'axios';

const apiURL: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000'; // Default to localhost if not set
// const apiKey: string = process.env.REACT_APP_API_KEY || "";

// Create an axios instance with a base URL
const api = axios.create({
  baseURL: apiURL,
  withCredentials: true
});

const headers = {
    'Content-Type': 'application/json',
    // 'X-API-Key': apiKey,
};

function isValidUrl(url: string) {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // domain name
        'localhost|' + // localhost
        '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
        '\\[?[a-f0-9]*:[a-f0-9:%.]*\\]?)' + // IPv6
        '(:\\d+)?' + // port
        '(\\/[a-zA-Z0-9\\-._~:?#[\\]@!$&\'()*+,;=%]*)*$', // path
        'i'
    );
    return urlPattern.test(url);
}

// Function to parse URL and return hostname without prefixes
export function getHostname(url: string) {
    try {
        let urlSanitized = url;

        // Ensure the URL has a valid structure
        if (!isValidUrl(urlSanitized)) {
            throw new Error('Invalid URL structure: ' + urlSanitized);
        }

        //ensure protocol 
        if (!/^https?:\/\//i.test(urlSanitized)) {
            urlSanitized = 'http://' + urlSanitized;
        }

        // Escape special characters
        urlSanitized = encodeURI(urlSanitized);

        const parsedUrl = new URL(urlSanitized); // Create a URL object
        const hostname = parsedUrl.hostname; // Get the hostname
        return hostname.replace(/^www\./, ''); // Remove 'www.' prefix
    } catch (error) {
        console.error('Failed to get hostname: ', error); // Handle invalid URL
        return ""; // Return null for invalid URLs
    }
}

// Function to generate session id and return it
export async function createNewSession() {
    try {
        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            return JSON.parse(sessionData);
        }
        
        let userId = await get_user_id();
        const sessionResponse = await api.post('/create_session', {
            user_id: userId.toString(),
            jurisdiction: localStorage.getItem('jurisdiction') || 'India',
            name: 'New Session'
            }, {
                headers: headers
            }
        );
        localStorage.setItem('session', JSON.stringify(sessionResponse.data));
        return sessionResponse.data;
    } catch (error) {
        localStorage.removeItem('user');
        localStorage.removeItem('session');
        console.error('Failed to create new session:', error); 
        return ""; 
    }
}

export async function get_user_email() {
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    return userData.user_email;
}

export async function get_user_id() {
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    return userData.user_id;
}

// Function to get user details
export async function getUserDetails(userEmail: string) {
    try {
        console.log("userEmail", userEmail);
        console.log("Headers", headers);
        const userResponse = await api.post('/get_user', {
            user_email: userEmail
            }, {
                headers: headers
            }
        );
        console.log("User Response", userResponse);
        return userResponse.data;
    } catch (error) {
        console.error('Invalid User:', error);
        return null;
    }
}

// Function to create a new user
export async function createNewUser(userEmail: string, userName: string) {
    try {
        const userResponse = await api.post('/create_user', {
            user_email: userEmail,
            user_name: userName
            }, {
                headers: headers
            }
        );
        return userResponse.data;
    } catch (error) {
        console.error('Invalid User:', error);
        return null;
    }
}

export function isBookmarkSelected(bookmarks: { answerId: number }[], answerId: number) {
    return bookmarks.some(bookmark => bookmark.answerId === answerId);
}

export function getBookmarkId(bookmarks: { answerId: number, bookmarkId: number }[], answerId: number) : number {
    return bookmarks.filter(bookmark => bookmark.answerId === answerId).map(bookmark => bookmark.bookmarkId)[0];
}

// Function to get unique domains from an array of URLs
export function getUniqueDomains(urls: string[]): string[] {
    if (!urls || urls.length === 0) return []; 
    const domains = new Set<string>(); 
    urls.forEach(url => {
        const hostname = getHostname(url); 
        if (hostname) {
            domains.add(hostname); 
        }
    });
    return Array.from(domains); 
}

export function formatDate(sourceDate: string) {
    const [day, month, year] = sourceDate.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    return formattedDate.toLocaleDateString();
    // return new Intl.DateTimeFormat(navigator.language, {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    // }).format(formattedDate);
}

export function hasSufficientTokens(tokens: string | undefined , min: number = 0){
    return ((Number(tokens) ?? 0) > min)
}

export function cookieCleanup() {
    document.cookie = 'app_access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
    localStorage.removeItem("user");
}