import axios from 'axios';

const apiURL: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000'; // Default to localhost if not set
// const apiKey: string = process.env.REACT_APP_API_KEY || "";

// Create an axios instance with a base URL
const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

const headers = {
    'Content-Type': 'application/json',
    // 'X-API-Key': apiKey,
};

export const researchApi = {
  submitQuery: async (query: string, onProgress: (progressData: { progress_text: string, progress_subtext: string }) => void) => {
    try {
      const response = await api.post('/research', { query }, {
        headers: headers,
        responseType: 'text',
        onDownloadProgress: (progressEvent) => {
          const data = progressEvent.event.target.responseText;
          const chunks = data.split('\n').filter(Boolean);
          const latestChunk = JSON.parse(chunks[chunks.length - 1]);
          
          if (latestChunk.type === 'progress') {
            onProgress(latestChunk.data);
          }
        }
      });

      // Process the final response
      const chunks = response.data.split('\n').filter(Boolean);
      const finalChunk = JSON.parse(chunks[chunks.length - 1]);
      
      if (finalChunk.type === 'result') {
        return finalChunk.data;
      } else {
        throw new Error('Unexpected final chunk type');
      }
    } catch (error) {
      console.error('Error submitting research query:', error);
      throw error;
    }
  },

  getSessions: async (userId: string) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      const response = await api.post('/get_all_sessions', { 
        user_id: userId 
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching sessions:', error);
      throw error;
    }
  },

  getSessionDetails: async (sessionId: string) => {
    try {
      if (!sessionId) {
        throw new Error('Session ID is required');
      }
      const response = await api.post('/get_session_details', { 
        session_id: sessionId
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching session details:', error);
      throw error;
    }
  },

  getSessionSummaries: async (sessionIds: string[]) => {
    try {
      if (!sessionIds || sessionIds.length === 0) {
        throw new Error('Session IDs are required');
      }
      const response = await api.post('/get_session_summaries', { 
        session_ids: sessionIds
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching session summaries:', error);
      throw error;
    }
  },

  deleteSession: async (sessionId: string) => {
    try {
      if (!sessionId) {
        throw new Error('Session ID is required');
      }
      await api.post('/delete_session', { 
        session_id: sessionId
      }, {
        headers: headers
      });
    } catch (error) {
      console.error('Error deleting session:', error);
      throw error;
    }
  },

  getBookmarks: async (userId: string) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      const response = await api.post('/get_all_bookmarks', { 
        user_id: userId
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
      throw error;
    }
  },

  getBookmarkDetails: async (bookmarkId: number) => {
    try {
      if (!bookmarkId) {
        throw new Error('Bookmark ID is required');
      }
      const response = await api.post('/get_bookmark_details', { 
        bookmark_id: bookmarkId
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching bookmark details:', error);
      throw error;
    }
  },

  deleteBookmark: async (bookmarkId: number) => {
    try {
      if (!bookmarkId) {
        throw new Error('Bookmark ID is required');
      }
      await api.post('/delete_bookmark', { 
        bookmark_id: bookmarkId
      }, {
        headers: headers
      });
    } catch (error) {
      console.error('Error deleting bookmark:', error);
      throw error;
    }
  },

  createBookmark: async (answerId: number, userId: string) => {
    try {
      if (!answerId) {
        throw new Error('Answer ID is required');
      }
      const response = await api.post('/create_bookmark', {
        answer_id: answerId, 
        user_id: userId 
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error creating bookmark:', error);
      throw error;
    }
  },

  setUserFeedback: async (sessionId: string, answerId: number, feedbackValue: number, feedbackText: string) => {
    try {
      if (!sessionId || !answerId || feedbackValue === null) {
        throw new Error('Session ID, Answer ID, and Feedback Value are required');
      }
      const response = await api.post('/set_user_feedback', {
        session_id: sessionId,
        answer_id: answerId,
        feedback_value: feedbackValue,
        feedback_text: feedbackText
      }, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Error setting user feedback:', error);
      throw error;
    }
  },

  getSearchResultsFromDB: async( queryId : number, sessionId: string) => {
    try {
      if (!queryId) {
        throw new Error('Query ID is required');
      }
      const response = await fetch(`${api.defaults.baseURL}/get_search_results`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          query_id: queryId,
          session_id: sessionId,
        }),
        credentials: 'include'
      });

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const searchResultsReader = response.body.getReader();
      const searchResultsDecoder = new TextDecoder();

      let result = '';
      let done = false;
      while (!done) {
        const { value, done: doneReading } = await searchResultsReader.read();
        done = doneReading;
        if (value) {
          result += searchResultsDecoder.decode(value, { stream: !done });
        }
      }

      return result.replace(/\n/g, '');
    } catch (error) {
      console.error('Error fetching sessions:', error);
      throw error;
    }

  },

  getSessionSearchResultsFromDB: async( sessionId: string) => {
    try {
      if (!sessionId) {
        throw new Error('Session ID is required');
      }
      const response = await api.post('/get_session_search_results', { 
        session_id: sessionId,
      }, {
        headers: headers
      });

      return response.data;

    } catch (error) {
      console.error('Error fetching sessions:', error);
      throw error;
    }

  }

  
};