import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import Layout from '../components/Layout';
import { useAuth } from '../contexts/AuthContext';
import { CompassIcon } from 'lucide-react';
import Logo from '../components/common/Logo'

const apiURL: string = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
// const apiKey: string = process.env.REACT_APP_API_KEY || "";

const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

const headers = {
    'Content-Type': 'application/json',
    // 'X-API-Key': apiKey,
};

const LoginPage: React.FC = () => {
  const location = useLocation();
  const [email, ] = useState<string>(location.state?.email || '');
  const navigate = useNavigate();
  const [networkError, setNetworkError] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const { setUser, checkAuth } = useAuth();
  const otpInputRef = useRef<HTMLInputElement>(null);

  const handleNetworkError = useCallback(() => {
    setNetworkError(false);
    alert("Server not found. Please try again later.");
    navigate('/');
    return null;
  }, [navigate]);

  const startLogin = useCallback(async () => {
    if (email.trim() === '') {
        navigate('/');
    } else {
        console.log("Logging in with", email);
        try {
            await api.post('/login_with_email', {
                user_email: email,
                user_name: email,
            }, { headers });
        } catch (error) {
            if (error instanceof Error && 'response' in error && 
                typeof error.response === 'object' && error.response && 'status' in error.response) {
              if (error.response.status === 404) {
                navigate('/contact-us');
              }
            }
            setNetworkError(true);
            console.error("Error starting login:", error);
        }
    }
  }, [email, navigate]);

  const VerifyOtp = useCallback(async () => {
    const otp = otpInputRef.current?.value || '';

    try {
        const response = await api.post('/verify_otp', {
              user_email: email,
              otp: otp,
        }, { headers });
        
        if (response?.data) {
            setWrongOtp(false);
            setUser(response.data);
            localStorage.removeItem('user');
            localStorage.removeItem('session');
            localStorage.setItem('user', JSON.stringify(response.data));
            checkAuth();
            navigate('/research');
        } else {
            setWrongOtp(true);
        }
    } catch (error) {
      if(error instanceof AxiosError && error?.response?.status === 401) {
        setWrongOtp(true);
      } else {
        if (error instanceof Error && 'response' in error && 
          typeof error.response === 'object' && error.response && 'status' in error.response) {
          if (error.response.status === 404) {
            navigate('/contact-us');
          }
        }
        setNetworkError(true);
        console.error("Error verifying OTP:", error);
      }
    }
  }, [email, navigate, setUser, checkAuth]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      VerifyOtp();
    }
  }, [VerifyOtp]);

  useEffect(() => {
    startLogin();
  }, [startLogin]);

  return (
    <div className="min-h-screen bg-white flex flex-col h-full">
      <Layout isLandingPage={true} >
        <main className="flex-grow flex flex-col items-center justify-center h-full">
          <div className='w-full flex items-center justify-center h-10'>
          <Logo variant="steel" size="lg" />
          </div>
          <div className="bg-white p-8 rounded-lg border border-gray-300 w-full max-w-md relative z-10">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Enter the code you received on your email
            </h2>
            {networkError ? handleNetworkError() : null}
            {wrongOtp && (
              <p className="text-red-500 text-center mb-4">
                Wrong OTP. Please try again.
              </p>
            )}
            <input
              id="otp"
              ref={otpInputRef}
              type="text"
              placeholder="6-digit code"
              className="px-2 py-2 border border-gray-300 rounded-sm text-sm focus:outline-none focus:border-black focus:ring-black placeholder-gray-300 w-[100%] placeholder:text-md"
              onKeyDown={handleKeyDown}
            />
            <div className="mt-2 flex justify-between items-center">
              <button
                className="text-gray-600 hover:text-gray-800 text-sm"
                onClick={startLogin}
              >
                Resend Code
              </button>
              <button 
                className="bg-gray-800 text-white text-sm px-4 py-2 rounded-md hover:bg-gray-700" 
                onClick={VerifyOtp}
              >
                Sign In
              </button>
            </div>
          </div>
        </main>
      </Layout>
    </div> 
  );
};

export default LoginPage;