interface LogoProps {
    variant?: 'blue' | 'steel';
    size?: 'sm' | 'md' | 'lg';
  }
  
  const Logo: React.FC<LogoProps> = ({ variant = 'blue', size = 'md' }) => {
    const colors = {
      blue: 'text-cyan-700',
      steel: 'text-gray-700',
    };
  
    const sizes = {
      sm: {
        container: 'w-4 h-4',
        text: 'text-sm',
      },
      md: {
        container: 'w-6 h-6',
        text: 'text-md',
      },
      lg: {
        container: 'w-8 h-8',
        text: 'text-lg',
      },
    };
  
    return (
      <div className="flex items-center text-black">
        <img 
          src={`/logo-${size}-${variant}.png`} 
          alt="Uttara Labs Logo" 
          className={`${sizes[size].container} mr-1`} 
        />
        <span className={`${colors[variant]} font-semibold font-avenir ${sizes[size].text} flex-shrink-0 mr-1`}>
          Uttara
        </span>
        <span className={`${colors[variant]} font-light font-avenir ${sizes[size].text} flex-shrink-0`}>
          Labs
        </span>
      </div>
    );
  };
  
  export default Logo;