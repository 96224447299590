import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import Home from './lib/pages/HomePage';


const container = document.getElementById('root');
const root = createRoot(container!);
// Add console.log to debug the environment variable
console.log('REACT_APP_RESEARCH_FRONTEND:', process.env.REACT_APP_RESEARCH_FRONTEND);
const Component = process.env.REACT_APP_RESEARCH_FRONTEND === 'true' ? App : Home;
root.render(
  // <React.StrictMode> disabling strict mode as it is meant for stability testing only
    <Component />
  // </React.StrictMode>,
);
