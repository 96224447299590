import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { useAuth } from "../contexts/AuthContext";
import { useContext, useRef } from "react";
import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import { ChatDataContext } from "../contexts/ChatDataContext";
import { RefreshCw, SettingsIcon } from "lucide-react";

const SettingsPage: React.FC = () => {
    const {user} = useAuth();
    const chatData = useContext(ChatDataContext);
    const generalSection = useRef<HTMLDivElement | null>(null);
    const billingSection = useRef<HTMLDivElement | null>(null);

    const handleScrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Section ref is not defined');
        }
    };

    return (
        <Layout subHeader="settings">
            <div className="flex flex-col w-full h-auto bg-white mx-8 rounded-md border border-gray-200 items-center">
                <div className="flex flex-row justify-center items-center my-8">
                    <SettingsIcon className="w-4 h-4 mr-1.5 text-slate-500"/>
                    <h2 className="text-xl font-semibold text-gray-900">
                        Settings
                    </h2>
                </div>
                <div className="flex flex-col p-6 border max-w-fit justify-center items-center border-gray-200 rounded-md h-fit">
                    <h1 className="text-lg font-semibold text-gray-900 mb-6">Profile</h1>
                    <div>
                    { user && (
                                <div className="flex flex-col">
                                    <div className="flex flex-row gap-2 border-t border-black py-4 px-4">
                                        <div className="w-36 text-gray-500">Name</div>
                                        <div>{user.user_name}</div>
                                    </div>
                                    <div className="flex flex-row gap-2 border-t border-black py-4 px-4">
                                        <div className="w-36 text-gray-500">Email</div>
                                        <div>{user.user_email}</div>
                                    </div>
                                    <div className="flex flex-row gap-2 border-t border-black py-4 px-4">
                                        <div className="w-36 text-gray-500">Created</div>
                                        <div>{new Date(user.created_at).toLocaleDateString()}</div>
                                    </div>
                                    <div className="flex flex-row gap-2 border-b border-t border-black py-4 px-4 items-center">
                                        <div className="w-36 text-gray-500">Tokens</div>
                                        <div className={`${Number(user.user_tokens) < 0 ? "text-red-600" : ""}`}>{user.user_tokens}</div>
                                        <Button className="w-5 h-5 text-green-500" size="icon" variant="ghost" onClick={chatData?.refreshUser} aria-label="Refresh">
                                            <RefreshCw size={15} />
                                        </Button>
                                    </div>
                                    <div className="mt-8 mb-4">
                                    <p className="text-gray-500 text-sm">Get in touch with us at <a href="mailto:hello@uttaralabs.com" className="text-blue-500">hello@uttaralabs.com</a> to purchase more tokens</p>
                                    </div>
                                </div>)}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SettingsPage;
