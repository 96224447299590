import React, { useContext, useState, useCallback, useMemo, FC, ReactNode, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { ChatDataContext, SuggestedQuestion } from '../../contexts/ChatDataContext';
import ChatProgressComponent from './ChatProgressComponent';
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';
import { researchApi } from '../../apis/research';
import { useAuth } from '../../contexts/AuthContext';
import { getBookmarkId } from '../../utils/commonUtil';
import { Button } from '../../../components/ui/button';
import { Badge } from '../../../components/ui/badge';
import { Separator } from '../../../components/ui/separator';
import {
    Sheet,
    SheetContent,
    SheetTrigger,
} from "../../../components/ui/sheet"
import SourceComponent from './SourceComponent';
import { SearchIcon,
    ThumbsUpIcon,
    ThumbsDownIcon,
    BookOpenIcon,
    Quote,
    Share2,
} from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from "../../../components/ui/dropdown-menu"

const html2pdf = require('html2pdf.js');

interface ButtonCitationProps {
    number: number;
    queryId: number;
    answerId: number;
    onClick: (number: number, queryId: number, answerId: number) => void;
}

interface HighlightState {
    [key: number]: boolean;
}

const ButtonCitation: FC<ButtonCitationProps> = ({ number, queryId, answerId, onClick }) => (
    <button
        className="button-citation inline-flex items-center"
        onClick={() => onClick(number, queryId, answerId)}
        aria-label={`Citation ${number}`}
        style={{ lineHeight: '1', verticalAlign: 'super' }}
    >
        {number}
    </button>
);

interface FeedbackState {
    [answerId: number]: number | null;
}

interface ChatStreamComponentProps {
    setQuoteText?: (text: string) => void;
}

const ChatStreamComponent: FC<ChatStreamComponentProps> = ({ setQuoteText }) => {
    const chatData = useContext(ChatDataContext);
    const [savedAnswers, setSavedAnswers] = useState<{ [answerId: number]: boolean }>({});
    const [saveInProgress, setSaveInProgress] = useState<{ [answerId: number]: string }>({});
    const { user } = useAuth();
    const answerRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const [highlightStates, setHighlightStates] = useState<HighlightState>({});
    const [feedbackState, setFeedbackState] = useState<FeedbackState>({});
    const [selectedText, setSelectedText] = useState("");
    const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
    const [showQuoteButton, setShowQuoteButton] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isAutoScrollingSet, setIsAutoScrollingSet] = useState(false);

    const handleSave = useCallback((answerId: number) => {
        async function saveBookmark() {
            if (chatData && user) {
                try {
                    const ans = Number(answerId);
                    setSaveInProgress(prev => ({ ...prev, [answerId]: "Saving..." }));
                    await researchApi.createBookmark(ans, user.user_id);
                    chatData.updateBookmarks();
                    setSavedAnswers(prev => ({ ...prev, [answerId]: true }));
                } catch (error) {
                    console.error('Error fetching bookmark details:', error);
                } finally {
                    setSaveInProgress(prev => ({ ...prev, [answerId]: "" }));
                }
            }
        }
        async function deleteBookmark() {
            if (chatData && user) {
                try {
                    const bookmarkId = getBookmarkId(chatData?.selectedBookmarks, Number(answerId));
                    setSaveInProgress(prev => ({ ...prev, [answerId]: "Deleting..." }));
                    await researchApi.deleteBookmark(bookmarkId);
                    chatData.updateBookmarks();
                    setSavedAnswers(prev => ({ ...prev, [answerId]: false }));
                } catch (error) {
                    console.error('Error fetching bookmark details:', error);
                } finally {
                    setSaveInProgress(prev => ({ ...prev, [answerId]: "" }));
                }
            }
        }
        if (savedAnswers[answerId]) {
            deleteBookmark();
        } else {
            saveBookmark();
        }
    }, [chatData, user, savedAnswers]);

    const handleShareFormat = useCallback((format: 'pdf' | 'html') => {
        const content = document.createElement('div');
        const chatHistory = chatData?.chatHistory.map(chat => (
            `<h2>${chat.query}</h2>
            ${ReactDOMServer.renderToString(
                <ReactMarkdown className="markdown-content">
                    {chat.answer}
                </ReactMarkdown>
            )}`
        )).join('');

        // Group sources by URL
        if(!chatData?.queryId)
            return;
        const currentQuerySources = chatData?.sources[chatData?.queryId];
        const groupedSources = currentQuerySources.reduce((acc, source, index) => {
            if (!acc[source.url]) {
                acc[source.url] = { title: source.title, indices: [] };
            }
            acc[source.url].indices.push(index + 1);
            return acc;
        }, {} as { [url: string]: { title: string, indices: number[] } });

        // Generate sources section
        const sources = groupedSources
            ? Object.entries(groupedSources).map(([url, { title, indices }]) => `
                <div style="margin-bottom: 1rem;">
                    <h3 style="font-weight: 600; margin-bottom: 0.25rem;">${title}</h3>
                    <a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #2563EB; word-break: break-all;">
                        ${url}
                    </a>
                    <div style="margin-top: 0.5rem;">
                        ${indices.map(index => `
                            <span style="
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                width: 24px;
                                height: 24px;
                                color: #168834;
                                margin-right: 0.5rem;
                                font-weight: 600;
                                font-size: 0.875rem;
                            ">[${index}]</span>
                        `).join('')}
                    </div>
                </div>
            `).join('')
            : '<p>No sources available</p>';

        content.innerHTML = `
            <html>
            <head>
                <style>
                    body {
                        font-family: Inter, sans-serif;
                        line-height: 1.5;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    h1 {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                    h2 {
                        font-size: 20px;
                        font-weight: semibold;
                        margin-top: 30px;
                        margin-bottom: 10px;
                    }
                    .markdown-content {
                        font-size: 16px;
                    }
                    .markdown-content p {
                        margin-bottom: 15px;
                    }
                    .markdown-content ul, .markdown-content ol {
                        margin-bottom: 15px;
                        padding-left: 20px;
                    }
                    .markdown-content li {
                        margin-bottom: 5px;
                    }
                    .markdown-content pre {
                        background-color: #f4f4f4;
                        padding: 10px;
                        border-radius: 4px;
                        overflow-x: auto;
                    }
                    .markdown-content code {
                        font-family: monospace;
                        font-size: 14px;
                    }
                    .uttara-labs-icon {
                        width: 100px;
                        margin-bottom: 20px;
                    }
                    .sources {
                        margin-top: 40px;
                        border-top: 1px solid #ccc;
                        padding-top: 20px;
                    }
                    .sources h2 {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                </style>
            </head>
            <body>
                <style>
                    .text-uorange {
                        color: #E7715D;
                        font-weight: 500;
                        font-family: 'Outfit', sans-serif;
                    }
                    .text-ucyan {
                        color: #54929A;
                        font-weight: 500;
                        font-family: 'Outfit', sans-serif;
                    }
                </style>
                <h1><strong>Uttara Labs</strong></h1>
                <h2>Research Results</h2>
                ${chatHistory}
                <div class="sources">
                    <h2>Sources
                    <p style="font-size: 0.8em;">[for the last answer only]</p></h2>
                    ${sources}
                </div>
            </body>
            </html>
        `;

        if (format === 'pdf') {
            const opt = {
                margin: 10,
                filename: 'research_results.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                enableLinks: true
            };
            html2pdf().from(content).set(opt).save();
        } else if (format === 'html') {
            const blob = new Blob([content.outerHTML], { type: 'text/html;charset=utf-8' });
            saveAs(blob, 'research_results.html');
        }
    }, [chatData]);

    const handleSources = useCallback((queryId: number, answerId: number) => {
        console.log("Sources clicked", queryId, answerId);
        answerId === 0 ? chatData?.setSourceQueryId(null)
            : chatData?.switchSources( 0, queryId, answerId );
    }, [chatData]);

    const handleFeedback = useCallback(async (answerId: number, feedbackValue: number) => {
        if (chatData) {
            try {
                await researchApi.setUserFeedback(chatData?.sessionId as string, answerId, feedbackValue, "");
                console.log(`Feedback submitted for answer ${answerId}: ${feedbackValue}`);
            } catch (error) {
                console.error('Error submitting feedback:', error);
            }
        }
    }, [chatData]);

    const handleThumbsUp = useCallback((answerId: number) => {
        if (feedbackState[answerId] !== 5) {
            handleFeedback(answerId, 5);
            setFeedbackState(prev => ({ ...prev, [answerId]: 5 }));
        } else {
            handleFeedback(answerId, 0);
            setFeedbackState(prev => ({ ...prev, [answerId]: null }));
        }
    }, [handleFeedback, feedbackState]);

    const handleThumbsDown = useCallback((answerId: number) => {
        if (feedbackState[answerId] !== 1) {
            handleFeedback(answerId, 1);
            setFeedbackState(prev => ({ ...prev, [answerId]: 1 }));
        } else {
            handleFeedback(answerId, 0);
            setFeedbackState(prev => ({ ...prev, [answerId]: null }));
        }
    }, [handleFeedback, feedbackState]);

    useEffect(() => {
        if (chatData?.highlightedAnswerId !== null) {
            const answerIndex = chatData?.chatHistory.findIndex(chat => Number(chat.answerId) === Number(chatData?.highlightedAnswerId)) as number;

            setHighlightStates(prev => ({ ...prev, [answerIndex]: true }));

            answerRefs.current[answerIndex]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            const timer = setTimeout(() => {
                chatData?.setHighlightedAnswerId(null);
                setHighlightStates(prev => ({ ...prev, [answerIndex]: false }));
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [chatData, chatData?.highlightedAnswerId]);

    useEffect(() => {
        if (chatData?.selectedBookmarks && chatData?.selectedBookmarks.length > 0) {
            const savedState = chatData?.selectedBookmarks.reduce((acc, bookmark: { bookmarkId: number, answerId: number }) => {
                acc[bookmark.answerId] = true;
                return acc;
            }, {} as { [answerId: number]: boolean });
            setSavedAnswers(savedState);
        }
    }, [chatData?.selectedBookmarks]);

    useEffect(() => {
        if (chatData?.chatHistory && chatData?.chatHistory.length > 0) {
            const savedState = chatData.chatHistory.reduce((acc, chat) => {
                acc[chat.answerId] = chat.userFeedback || null;
                return acc;
            }, {} as FeedbackState);
            setFeedbackState(savedState);
        }
    }, [chatData?.chatHistory]);

    const handleScroll = useCallback((event: Event) => {
        const container = event.target as HTMLDivElement;
        const currentScrollPosition = container.scrollTop;
        const prevScrollPosition = scrollPosition;

        setScrollPosition(currentScrollPosition);

        if (currentScrollPosition < prevScrollPosition) {
            setIsAutoScrollingSet(false);
        }
    }, [scrollPosition]);

    useEffect(() => {
        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener('scroll', handleScroll);
            return () => {
                currentContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    useEffect(() => {
        if (isAutoScrollingSet) {
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatData?.answer, chatData?.chatHistory]);

    useEffect(() => {
        if (chatData?.lastRenderedQueryId === -1) {
            setIsAutoScrollingSet(true);
        }
    }, [chatData?.lastRenderedQueryId]);

    const handleCitationClick = useCallback((number: number, queryId: number, answerId: number) => {
        chatData?.switchSources( number, queryId, answerId );
    }, [chatData]);

    const handleSuggestionClick = useCallback((suggestion: string) => {
        chatData?.setCurrentQuery(suggestion);
    }, [chatData]);

    const TextRenderer: FC<{ children: ReactNode; node?: any; queryId: number; answerId: number; }> = useCallback(({ children, node, queryId, answerId }) => {
        const processText = (text: string) => {

            text = text.replace(/\【/g, '[').replace(/\】/g, ']');

            const pattern = /(?<!\.)(?:\s+)(\[\d+](?:\s*\[\d+])*)\s*\.?/g;
            const processedText = text.replace(pattern, (match, citations) => {
                const cleanedCitations = citations.replace(/\.$/, '');
                return `.${cleanedCitations}`;
            });

            const regex = /\[(\d+)\]/g;
            const parts = processedText.split(regex);
            return parts.map((part, index) => {
                if (index % 2 === 1) {
                    const citationNumber = parseInt(part, 10);
                    return (
                        <ButtonCitation
                            key={`citation-${index}`}
                            number={citationNumber}
                            queryId={queryId}
                            answerId={answerId}
                            onClick={handleCitationClick}
                        />
                    );
                }
                return part;
            });
        };

        if (typeof children === 'string') {
            if (node?.tagName === 'li') {
                return <li>{processText(children)}</li>;
            } else if (node?.tagName === 'p') {
                return <p>{processText(children)}</p>;
            }
            return <>{processText(children)}</>;
        }

        if (Array.isArray(children)) {
            if (node?.tagName === 'li') {
                return (
                    <li>
                        {children.map((child, index) =>
                            typeof child === 'string' ?
                                <React.Fragment key={index}>{processText(child)}</React.Fragment> :
                                <React.Fragment key={index}>{child}</React.Fragment>
                        )}
                    </li>
                );
            } else if (node?.tagName === 'p') {
                return (
                    <p>
                        {children.map((child, index) =>
                            typeof child === 'string' ?
                                <React.Fragment key={index}>{processText(child)}</React.Fragment> :
                                <React.Fragment key={index}>{child}</React.Fragment>
                        )}
                    </p>
                );
            }
            return (
                <>
                    {children.map((child, index) =>
                        typeof child === 'string' ?
                            <React.Fragment key={index}>{processText(child)}</React.Fragment> :
                            <React.Fragment key={index}>{child}</React.Fragment>
                    )}
                </>
            );
        }

        if (node?.tagName === 'li') {
            return <li>{children}</li>;
        } else if (node?.tagName === 'p') {
            return <p>{children}</p>;
        }
        return <span>{children}</span>;
    },[handleCitationClick]);

    const renderMarkdownAnswer = useCallback((queryId: number, answerId: number, answer: string) => {
        return <ReactMarkdown className="text-black mb-4 text-justify text-md font-lora leading-7"
                    components={{
                p: ({ node, children }) => (
                    <TextRenderer 
                        node={node} 
                        queryId={queryId} 
                        answerId={answerId}
                    >
                        {children}
                    </TextRenderer>
                ),
                li: ({ node, children }) => (
                    <TextRenderer 
                        node={node} 
                        queryId={queryId} 
                        answerId={answerId}
                    >
                        {children}
                    </TextRenderer>
                ),
            }}
        >
            {answer}
        </ReactMarkdown>
    },[TextRenderer]);

    const renderActionButtons = useCallback((answerId: number, queryId: number) => (
        <div className="flex space-x-2">
            <Button
                className={`${savedAnswers[answerId] ? 'bg-gray-200' : ''}`}
                onClick={() => handleSave(answerId)}
                size="xs"
                variant="outline"
                disabled={!!saveInProgress[answerId]}
            >
                <svg
                    className="w-3 h-3 text-orange-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                    />
                </svg>
                <span className="ml-2">
                    {saveInProgress[answerId]
                        ? saveInProgress[answerId]
                        : (savedAnswers[answerId] ? 'Saved' : 'Save')}
                </span>
            </Button>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button size="xs" variant="outline">
                        <Share2 className="w-6 h-3 text-purple-500" />
                        Share
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-40" align="start">
                    <DropdownMenuItem onClick={() => handleShareFormat('pdf')}>
                        Share as PDF
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleShareFormat('html')}>
                        Share as HTML
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <Button
                onClick={() => handleSources(queryId, answerId)}
                size="xs"
                variant="outline"
            >
                <BookOpenIcon className="w-3 h-3 text-blue-500" />
                <span className="ml-2">Sources</span>
            </Button>
            <Button
                onClick={() => handleThumbsUp(answerId)}
                size="xs"
                variant="outline"
                className={feedbackState[answerId] === 5 ? 'bg-green-100' : ''}
            >
                <ThumbsUpIcon className="w-3 h-3 text-green-500" />
            </Button>
            <Button
                onClick={() => handleThumbsDown(answerId)}
                size="xs"
                variant="outline"
                className={feedbackState[answerId] === 1 ? 'bg-red-100' : ''}
            >
                <ThumbsDownIcon className="w-3 h-3 text-red-500" />
            </Button>
        </div>
    ),[feedbackState, handleSave, handleShareFormat, handleSources, handleThumbsDown, handleThumbsUp, saveInProgress, savedAnswers]);

    const renderSuggestedQuestions = (suggestedQuestions: SuggestedQuestion[]) => {
        return <div className="flex flex-col mb-4">
        <div className="flex items-center mt-1 mb-1 border-t border-gray-200 pt-8">
            <svg
                className="w-3 h-3 ml-2 text-violet-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                />
            </svg>
            <span className="text-base text-gray-800 ml-3">Suggestions</span>
        </div>
        {suggestedQuestions.map((suggestion, index) => (
            <div
                key={index}
                className="flex items-center border-t border-gray-200 pb-2 pt-2 hover:bg-accent cursor-pointer hover:text-gray-800 font-normal"
                onClick={() => handleSuggestionClick(suggestion.question_text)}
            >
                <SearchIcon className='w-3 h-3 text-gray-500 ml-1'/>
                <span className="text-sm text-gray-500 ml-2">{suggestion.question_text}</span>

            </div>
            ))}
            <Separator className="w-full" />
            <span className='mb-24'></span>    
        </div>
    }

    const chatHistoryMemo = useMemo(() => {
        if (chatData?.chatHistory && chatData?.chatHistory.length > 0) {
            return chatData.chatHistory.map((chat, index) => (
                <div key={`history-${index}`} id={`history-${index}`} className={`flex flex-col pr-4 ${highlightStates[index] ? 'bg-neutral-100 transition-colors bg-opacity-30' : ''}`}
                    ref={el => answerRefs.current[index] = el}>
                    <span className='mb-4'></span>
                    <h2 className="text-2xl font-bold font-lora mb-4">{chat.query}</h2>
                    {((chatData.lastRenderedQueryId === -1 || chat.queryId === chatData.lastRenderedQueryId) && index === chatData?.chatHistory.length - 1) && <ChatProgressComponent />}
                    {renderMarkdownAnswer(Number(chat.queryId), Number(chat.answerId), 
                        chat.answer === '' && index === chatData?.chatHistory.length - 1 && chat.queryId === chatData.queryId ? chatData.answer : chat.answer)}
                    {(index < chatData?.chatHistory.length - 1 || chatData?.lastRenderedQueryId !== -1) && (renderActionButtons(Number(chat.answerId), Number(chat.queryId)))}
                    <div className="flex flex-col mb-4"></div>
                    {chatData?.suggestedQuestions && index === chatData?.chatHistory.length - 1 && chatData?.suggestedQuestions.length > 0 && (renderSuggestedQuestions(chatData?.suggestedQuestions))}
                </div>
            ));
        } else {
            return <></>;
        }
    }, [chatData?.chatHistory, chatData?.query, renderMarkdownAnswer, renderActionButtons, chatData?.lastRenderedQueryId]);

    // Add this useEffect to handle text selection
    useEffect(() => {
        const handleTextSelection = () => {
            const selection = window.getSelection();
            if (selection && selection.toString().length > 0) {
                const range = selection.getRangeAt(0);
                const rect = range.getBoundingClientRect();
                
                setSelectedText(selection.toString());
                setButtonPosition({
                    x: rect.x + rect.width / 2,
                    y: rect.y - 30
                });
                setShowQuoteButton(true);
            }
        };

        // Add this new handler
        const handleSelectionChange = () => {
            const selection = window.getSelection();
            if (!selection || selection.toString().length === 0) {
                setShowQuoteButton(false);
            }
        };

        // Handle clicks outside
        const handleClickOutside = (e: MouseEvent) => {
            const selection = window.getSelection();
            if (!selection || selection.toString().length === 0) {
                setShowQuoteButton(false);
            }
        };

        document.addEventListener('mouseup', handleTextSelection);
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('selectionchange', handleSelectionChange); // Add this listener

        return () => {
            document.removeEventListener('mouseup', handleTextSelection);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('selectionchange', handleSelectionChange); // Clean up
        };
    }, []);

    return (
        <div 
            ref={containerRef}
            className="flex-grow overflow-y-auto"
            style={{ height: '100%' }}
        >
            {chatHistoryMemo}
            {showQuoteButton && (
                <div
                    className="fixed z-50"
                    style={{
                        left: `${buttonPosition.x}px`,
                        top: `${buttonPosition.y}px`,
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <Button
                        size="sm"
                        className="rounded-full w-8 h-8 p-0 border border-gray-200 bg-gray-200 hover:bg-gray-200"
                        onClick={() => {
                            if (setQuoteText) {
                                setQuoteText(selectedText);  // Set the quote text
                            }
                            setShowQuoteButton(false);
                        }}
                    >
                        <Quote className="h-4 w-4 text-gray-700" />
                    </Button>
                </div>
            )}
            <div ref={bottomRef} /> 

        </div>
    );
};

export default ChatStreamComponent;
