import React from 'react';
import Layout from '../components/Layout';
import Logo from '../components/common/Logo';
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card"
import { motion } from 'framer-motion';

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "../../components/ui/tooltip"

import { Code2, Zap, Briefcase, Activity } from 'lucide-react';

const Home: React.FC = () => {
    const cardConfig = [
        { icon: Code2, color: 'text-blue-500' },
        { icon: Zap, color: 'text-amber-500' },
        { icon: Briefcase, color: 'text-emerald-500' },
        { icon: Activity, color: 'text-purple-500' }
    ];

    return (
            <div 
                className="min-h-screen flex flex-col items-center justify-start relative p-4"
            >
                <div 
                    className="absolute inset-0 z-0"
                    style={{
                        backgroundImage: 'url("/bgpattern2.svg")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.1
                    }}
                />
                <div className='relative z-10 font-lora my-auto w-full max-w-7xl'>
                    <div className='flex flex-wrap sm:flex-nowrap sm:gap-x-16 gap-4 px-2'>
                        {[1, 2, 3, 4].map((number, index) => (
                            <motion.div
                                key={number}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    duration: 1.5,
                                    delay: index * 0.5,
                                    ease: "easeOut"
                                }}
                                className="w-[calc(50%-0.5rem)] sm:w-[275px]"
                            >
                                <Card className="h-[170px]">
                                    <CardHeader className="pb-2">
                                        <CardTitle className={cardConfig[index].color}>
                                            {React.createElement(cardConfig[index].icon, { size: 24 })}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <div className="grid w-full items-center">
                                            <div className="text-sm mt-4 leading-tight sm:text-base sm:leading-normal">
                                                {number === 1 && "LLMs have made law programmable"}
                                                {number === 2 && "Lawyers can now draft, research, review 10X faster"}
                                                {number === 3 && "They can now focus on more high value, bespoke work"}
                                                {number === 4 && "Clients can now enjoy real-time, interactive legal services"}
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        ))}
                    </div>
                    <motion.div 
                        className='mt-12 md:mt-24 flex flex-row gap-x-4 justify-center items-center'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1.5,
                            delay: 2.0,
                            ease: "easeOut"
                        }}
                    >
                        <div className='mt-1'>
                            <Logo variant="steel" size="lg" />
                        </div>
                        <span className='text-2xl text-slate-300'>|</span>
                        <p className='font-medium mt-1 italic'>10X faster legal services</p>
                    </motion.div>
                    <motion.div 
                        className="flex justify-center w-full mt-6" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1.5,
                            delay: 2.0,
                            ease: "easeOut"
                        }}
                    >
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                            <Button 
                                className='flex justify-center items-center font-sans mr-10'
                                onClick={() => window.location.href = 'mailto:hello@uttaralabs.com'}
                            >
                                Contact Us
                            </Button>
                        </TooltipTrigger>
                    <TooltipContent className='font-sans'>
                        hello@uttaralabs.com
                    </TooltipContent>
                    </Tooltip>
                    </TooltipProvider>
                    </motion.div>
                   
                </div>

            </div>
    );
};

export default Home;